body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-color {
  background-color: #e1e6de;
}

.popup {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000; /* Ensure it appears above other content */
}

.points-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1px; /* Adjust the space between boxes as needed */
}

.point-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* Adjust the size of the box as needed */
  height: 30px; /* Adjust the size of the box as needed */
  background-color: #cddcff; /* Adjust the background color as needed */
  border: 1px solid #0a58ca; /* Adjust the border as needed */
  cursor: pointer;
  user-select: none;
  margin: 0.5px; /* Adjust the margin as needed */
  border-radius: 5px; /* Adjust the border radius for rounded corners */
}

.header-style {
  font-family: "Marker Felt";
  font-weight: "bold";
}
